import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/nb';
import 'moment/locale/fi';
import 'moment/locale/da';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'simple-line-icons/css/simple-line-icons.css';
import App from './containers/App';
import './index.scss';
import { registerLocaleData } from './languages/languageHelper';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

registerLocaleData();
moment.locale('en');

ReactDOM.render(
  <Root>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Root>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
