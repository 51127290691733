import React from 'react';
import { FormattedMessage } from 'react-intl';
import { languageKeys } from '../languages/languageKeys';
import Link from 'react-router-dom/es/Link';
import OverridableFormattedMessage from '../languages/OverridableFormattedMessage';

function NotFoundPage() {
  return (
    <article className="page-not-found">
      <h1>404</h1>
      <h3>
        <OverridableFormattedMessage
          languageKey={languageKeys.PAGE_NOT_FOUND}
        />
      </h3>
      <h3>
        <Link to="/">
          <button className="btn btn--default">
            <FormattedMessage id={languageKeys.BACK_TO_STARTPAGE} />
          </button>
        </Link>
      </h3>
    </article>
  );
}

export default NotFoundPage;
