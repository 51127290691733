import PropTypes from 'prop-types';
import React from 'react';
import OverridableFormattedMessage from '../languages/OverridableFormattedMessage';

const OfficeInfo = ({ office, message }) => {
  return (
    <>
      <section className="broker-info__image broker-image">
        <div className="broker-image__wrapper">
          <div className="office-figure" />
        </div>
        <h3>{office.officeName}</h3>
      </section>

      <section className="broker-info__details">
        <h3 className="broker-info__details--mobile-show-name">
          {office.realOfficeName}
        </h3>
        {office.phone ? (
          <p>
            <a href={`tel:${office.phone}`}>{office.phone}</a>
          </p>
        ) : null}
        {office.email ? (
          <p>
            <a href={`mailto:${office.email}`}>{office.email}</a>
          </p>
        ) : null}
        {office.street ? <p>{office.street}</p> : null}
        <hr />
        <p>
          <b>
            <OverridableFormattedMessage languageKey={message} />
          </b>
        </p>
      </section>
    </>
  );
};

OfficeInfo.propTypes = {
  office: PropTypes.object.isRequired,
};

export default OfficeInfo;
