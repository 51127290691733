import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import BrokerInfo from '../components/BrokerInfo';
import InfoBox from '../components/InfoBox';
import { languageKeys } from '../languages/languageKeys';
import OverridableFormattedMessage from '../languages/OverridableFormattedMessage';
import * as fromBookingProgress from '../redux/modules/bookingProgress';

class ConfirmationPage extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const {
      booking: { office, timeSlot, contactInfo },
    } = this.props;

    if (!office || !timeSlot || !contactInfo) {
      return <Redirect to="/" />;
    }

    const dataTime = moment(timeSlot.event.startTime);
    return (
      <article className="confirmation-page">
        <header className="slideInBottom">
          <i className="icon-check" />
          <h1>
            <OverridableFormattedMessage
              languageKey={languageKeys.CONFIRMATION_PAGE_HEADING_TEXT}
            />
          </h1>
        </header>

        <section className="confirmation-page__details">
          <ul>
            <li className="slideInBottom-1">
              <i className="icon-location-pin" />{' '}
              <span>{contactInfo.address}</span>
            </li>
            <li className="slideInBottom-2">
              <i className="icon-clock" />{' '}
              <span>{dataTime.format('HH:mm')}</span>
            </li>
            <li className="slideInBottom-3">
              <i className="icon-calendar" />{' '}
              <span>
                {dataTime.format('dddd')}, {dataTime.format('DD')}{' '}
                {dataTime.format('MMMM')}
              </span>
            </li>
          </ul>
        </section>

        <section>
          <h3 className="confirmation-page--centered slideInBottom-4">
            <FormattedMessage id={languageKeys.WITH} />
          </h3>
          <InfoBox>
            <BrokerInfo
              broker={timeSlot.broker}
              office={office}
              message={languageKeys.CONFIRMATION_PAGE_BROKER_MESSAGE}
            />
          </InfoBox>
        </section>

        <footer className="confirmation-page--centered">
          <Link to="/" onClick={this.props.reset}>
            <FormattedMessage id={languageKeys.BOOK_ANOTHER_TIME} />
          </Link>
        </footer>
      </article>
    );
  }
}

function mapStateToProps(state) {
  return {
    booking: fromBookingProgress.bookingProgressSelector(state),
  };
}

export default connect(
  mapStateToProps,
  {
    reset: fromBookingProgress.reset,
  }
)(ConfirmationPage);
