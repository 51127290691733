import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import OfficeDropdown from '../components/OfficeDropdown';
import ZipForm from '../components/ZipForm';
import OverridableFormattedMessage from '../languages/OverridableFormattedMessage';
import { languageKeys } from '../languages/languageKeys';
import * as fromBookingProgress from '../redux/modules/bookingProgress';
import * as fromSelectOffice from '../redux/modules/selectOffice';
import * as fromSettings from '../redux/modules/settings';
import * as fromLoading from '../redux/modules/loading';
import { officeSelectionMethod } from '../utils/officeSelectionMethods';

class OfficePage extends Component {
  state = {
    selectedOffice: null,
  };

  static getDerivedStateFromProps(props) {
    if (props.selectedOffice) {
      return { selectedOffice: props.selectedOffice };
    }
    return null;
  }

  componentDidMount() {
    const { selectionMethod, fetchOffices } = this.props;

    if (selectionMethod === officeSelectionMethod.DROPDOWN) {
      fetchOffices();
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  handleOfficeChange = event => {
    const { offices, setSelectedOffice } = this.props;
    const selectedOffice = offices.find(
      office => office.eaOfficeId === event.target.value
    );

    setSelectedOffice(selectedOffice);
  };

  renderOfficeSelection = () => {
    const {
      offices,
      selectionMethod,
      setSelectedOffice,
      setLoading,
    } = this.props;

    switch (selectionMethod) {
      case officeSelectionMethod.ZIP:
        return <ZipForm onSubmit={setSelectedOffice} setLoading={setLoading} />;
      case officeSelectionMethod.DROPDOWN:
      default:
        return (
          <OfficeDropdown
            offices={offices}
            onChange={this.handleOfficeChange}
          />
        );
    }
  };

  render() {
    const { selectedOffice } = this.state;
    if (selectedOffice) {
      return <Redirect push to={`offices/${selectedOffice.eaOfficeId}`} />;
    }

    return (
      <article className="office-page">
        <header>
          <h1>
            <OverridableFormattedMessage
              languageKey={languageKeys.OFFICE_PAGE_HEADING_TEXT}
            />
          </h1>
          <p className="office-page__custom-p1" />
          <p className="office-page__custom-p2" />
        </header>
        <img alt="Home icon" src="/images/house-icon-2.png" />
        <section>
          <h3>
            <OverridableFormattedMessage
              languageKey={languageKeys.OFFICE_PAGE_SUBHEADING_TEXT}
            />
          </h3>
          {this.renderOfficeSelection()}
        </section>
      </article>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectionMethod: fromSettings.officeSelectionMethodSelector(state),
    offices: fromSelectOffice.officesSelector(state),
    selectedOffice: fromBookingProgress.selectedOfficeSelector(state),
  };
}

export default connect(
  mapStateToProps,
  {
    fetchOffices: fromSelectOffice.fetchOfficesRequest,
    setSelectedOffice: fromBookingProgress.setSelectedOffice,
    setLoading: fromLoading.setLoading,
  }
)(OfficePage);
