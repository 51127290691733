import moment from 'moment';
import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import {
  catchError,
  ignoreElements,
  map,
  switchMapTo,
  tap,
} from 'rxjs/operators';
import { API } from '../../utils/api';
import { officeSelectionMethod } from '../../utils/officeSelectionMethods';

// Actions
const FETCH_SETTINGS_REQUEST = 'settings/FETCH_SETTINGS_REQUEST';
const FETCH_SETTINGS_SUCCESS = 'settings/FETCH_SETTINGS_SUCCESS';
const FETCH_SETTINGS_FAILURE = 'settings/FETCH_SETTINGS_FAILURE';

// Reducer
export const initialState = {
  loading: true,
  name: '',
  integrityPolicyUrl: '',
  languageCode: 'en',
  translations: {},
  officeSelectionMethod: officeSelectionMethod.DROPDOWN,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_SETTINGS_REQUEST:
      return { ...state, loading: true };

    case FETCH_SETTINGS_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case FETCH_SETTINGS_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}

// Selectors
export const settingsSelector = state => state.settings;
export const languageCodeSelector = createSelector(
  settingsSelector,
  settings => settings.languageCode
);
export const officeSelectionMethodSelector = createSelector(
  settingsSelector,
  settings => settings.officeSelectionMethod
);
export const loadingSelector = createSelector(
  settingsSelector,
  settings => settings.loading
);
export const customerSelector = createSelector(
  settingsSelector,
  settings => settings.name
);
export const integrityPolicyUrlSelector = createSelector(
  settingsSelector,
  settings => settings.integrityPolicyUrl
);
export const customTranslationsSelector = createSelector(
  settingsSelector,
  settings => settings.translations
);

// Action Creators
export function fetchSettingsRequest() {
  return { type: FETCH_SETTINGS_REQUEST };
}

export function fetchSettingsSuccess(settings) {
  return { type: FETCH_SETTINGS_SUCCESS, payload: settings };
}

export function fetchSettingsFailure(error) {
  return { type: FETCH_SETTINGS_FAILURE, payload: error };
}

// Side Effects
export const fetchSettingsEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_SETTINGS_REQUEST),
    switchMapTo(
      ajax.getJSON(API.settings).pipe(
        map(response => fetchSettingsSuccess(response)),
        catchError(err => of(fetchSettingsFailure(err)))
      )
    )
  );

export const setMomentLocaleEpic = action$ =>
  action$.pipe(
    ofType(FETCH_SETTINGS_SUCCESS),
    tap(({ payload: { languageCode } }) => moment.locale(languageCode)),
    ignoreElements()
  );

export const epics = [fetchSettingsEpic, setMomentLocaleEpic];
