import PropTypes from 'prop-types';
import React from 'react';
import OverridableFormattedMessage from '../languages/OverridableFormattedMessage';

const BrokerInfo = ({ broker, office, message }) => {
  return (
    <>
      <section className="broker-info__image broker-image">
        <div className="broker-image__wrapper">
          {broker.photo ? (
            <figure
              alt={broker.fullName}
              style={{ backgroundImage: `url(${broker.photo})` }}
            />
          ) : (
            <figure />
          )}
        </div>
        <h3>
          {broker.firstName} <br /> {broker.lastName}
        </h3>
      </section>

      <section className="broker-info__details">
        <h3 className="broker-info__details--mobile-show-name">
          {broker.fullName}
        </h3>
        {broker.title && (
          <p>
            <b>{broker.title}</b>
          </p>
        )}
        <p>{office.officeName}</p>
        {broker.mobile ? (
          <p>
            <a href={`tel:${broker.mobile}`}>{broker.mobile}</a>
          </p>
        ) : null}
        {broker.email ? (
          <p>
            <a href={`mailto:${broker.email}`}>{broker.email}</a>
          </p>
        ) : null}
        <hr />
        <p>
          <b>
            <OverridableFormattedMessage languageKey={message} />
          </b>
        </p>
      </section>
    </>
  );
};

BrokerInfo.propTypes = {
  broker: PropTypes.object.isRequired,
  office: PropTypes.object.isRequired,
  message: PropTypes.string,
};

export default BrokerInfo;
