import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shuffle from 'shuffle-array';
import { languageKeys } from '../languages/languageKeys';
import Spinner from './Spinner';
import { pipe, sort, map, keys, ifElse } from 'ramda';
import { Link } from 'react-router-dom';

const isSelectedTime = (selectedTime, availableTimeSlots) => {
  if (!selectedTime) {
    return false;
  }

  return availableTimeSlots.some(
    timeSlot => timeSlot.event.id === selectedTime.event.id
  );
};

const getRandomTimeSlot = availableTimeSlots => shuffle(availableTimeSlots)[0];
const dateAsc = (a, b) => moment(a) - moment(b);
const renderPlaceholder = () => <div className="cell mute">-</div>;

class Calendar extends Component {
  previousWeek = () => {
    if (this.shouldDisablePreviousButton()) {
      return;
    }
    this.props.onWeekChange(this.props.now.clone().subtract(1, 'week'));
  };

  nextWeek = () => {
    this.props.onWeekChange(this.props.now.clone().add(1, 'week'));
  };

  shouldDisablePreviousButton = () => {
    const startOfSelectedWeek = this.props.now.clone().startOf('week');
    const startOfCurrentWeek = moment()
      .utc()
      .startOf('week');
    return startOfSelectedWeek.isSameOrBefore(startOfCurrentWeek);
  };

  getTimeData = () => {
    const { now } = this.props;
    const month = moment.months(now.month());
    const year = now.year();
    const week = now.week();
    return { month, year, week };
  };

  renderTimeSlots = freeTimeSlots => {
    const { onTimeChange, selectedTime } = this.props;
    const renderTimeSlot = timestamp => (
      <div key={timestamp} className="cell">
        <Link
          to="/user-details"
          role="button"
          onClick={this.handleNextButtonClick}
        >
          <span
            onClick={() =>
              onTimeChange(getRandomTimeSlot(freeTimeSlots[timestamp]))
            }
            className={classNames('calendar__column__item', {
              active: isSelectedTime(selectedTime, freeTimeSlots[timestamp]),
            })}
          >
            {moment(timestamp).format('HH:mm')}
          </span>
        </Link>
      </div>
    );

    return ifElse(
      Boolean,
      pipe(
        keys,
        sort(dateAsc),
        map(renderTimeSlot)
      ),
      renderPlaceholder
    )(freeTimeSlots);
  };

  renderDates = () => {
    const { now, loading, timeSlots } = this.props;
    const startOfWeek = now.clone().startOf('isoWeek');
    const endOfWeek = startOfWeek.clone().add(5, 'day');

    const days = [];
    let day = startOfWeek;
    while (day < endOfWeek) {
      days.push(day);
      day = day.clone().add(1, 'day');
    }

    return (
      <>
        {days.map((day, i) => (
          <div className="calendar__column" key={i}>
            <h4 className="day">{day.format('ddd')}</h4>
            <h2 className="number">{day.format('DD')}</h2>
            {!loading && (
              <div>{this.renderTimeSlots(timeSlots[day.format()])}</div>
            )}
          </div>
        ))}
        {loading && <Spinner />}
      </>
    );
  };

  render() {
    return (
      <section className="calendar slideInBottom-2">
        <header className="calendar__toggle">
          <span
            className={classNames('arrow', {
              'arrow--disabled': this.shouldDisablePreviousButton(),
            })}
          >
            <i onClick={this.previousWeek} className="icon-arrow-left" />
          </span>
          <h4 className="calendar__title">
            <FormattedMessage
              id={languageKeys.CALENDAR_TITLE}
              values={this.getTimeData()}
            />
          </h4>
          <span className="arrow">
            <i onClick={this.nextWeek} className="arrow icon-arrow-right" />
          </span>
        </header>
        <div className="calendar__grid-wrapper">
          <div className="calendar__grid">{this.renderDates()}</div>
        </div>
      </section>
    );
  }
}

Calendar.propTypes = {
  timeSlots: PropTypes.object.isRequired,
  now: PropTypes.object,
  selectedTime: PropTypes.object,
  onWeekChange: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
};

export default Calendar;
