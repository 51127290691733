import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import sv from 'react-intl/locale-data/sv';
import nb from 'react-intl/locale-data/nb';
import fi from 'react-intl/locale-data/fi';
import da from 'react-intl/locale-data/da';

export function registerLocaleData() {
  addLocaleData([...en, ...sv, ...nb, ...fi, ...da]);
}

export const messages = {
  en: require('./json/en.json'),
  sv: require('./json/sv.json'),
  nb: require('./json/nb.json'),
  fi: require('./json/fi.json'),
  da: require('./json/da.json'),
};
