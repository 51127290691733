// Actions
const SET_LOADING = 'loading/SET_LOADING';

// Reducer
export default function reducer(state = false, action = {}) {
  switch (action.type) {
    case SET_LOADING:
      return action.payload;

    default:
      return state;
  }
}

// Selectors
export const loadingSelector = state => state.loading;

// Action Creators
export function setLoading(loading) {
  return { type: SET_LOADING, payload: loading };
}
