// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
export const languageKeys = {
  OFFICE_PAGE_HEADING_TEXT: 'office_page_heading_text',
  OFFICE_PAGE_SUBHEADING_TEXT: 'office_page_subheading_text',
  OFFICE_PAGE_FIND_OFFICE: 'office_page_find_office',
  USER_DETAIL_PAGE_HEADING_TEXT: 'user_detail_page_heading_text',
  CONFIRMATION_PAGE_HEADING_TEXT: 'confirmation_page_heading_text',
  CONFIRMATION_PAGE_BROKER_MESSAGE: 'confirmation_page_broker_message',
  PAGE_NOT_FOUND: 'page_not_found',
  MANAGER: 'manager',
  BROKER: 'broker',
  NO_TIMES_AVAILABLE: 'no_times_available',
  CONTACT_ME: 'contact_me',
  CONTACT_US: 'contact_us',
  SELECT_YOUR_AREA: 'select_your_area',
  ZIP: 'zip',
  CHOOSE_DATE: 'choose_date',
  IS_A_REQUIRED_FIELD: 'is_a_required_field',
  BACK: 'back',
  NEXT: 'next',
  CALENDAR_TITLE: 'calendar_title',
  NAME: 'name',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobile_number',
  ADDRESS: 'address',
  BOOK: 'book',
  VALIDATION_EMAIL_INVALID: 'validation_email_invalid',
  VALIDATION_PHONE_INVALID: 'validation_phone_invalid',
  WITH: 'with',
  BOOK_ANOTHER_TIME: 'book_another_time',
  BACK_TO_STARTPAGE: 'back_to_startpage',
  LOADING: 'loading',
  SERVICE_UNAVAILABLE_HEADING_TEXT: 'service_unavailable_heading_text',
  SERVICE_UNAVAILABLE_SUBHEADING_TEXT: 'service_unavailable_subheading_text',
  NO_OFFICE_FOUND: 'no_office_found',
  INTEGRITY_POLICY_LINK_TEXT: 'integrity_policy_link_text',
  ALL: 'all',
  BROKERS: 'brokers',
  CONTACT_US_PAGE_HEADING: 'contact_us_page_heading',
  CONTACT_US_PAGE_TEXT: 'contact_us_page_text',
  CONTACT_US_PAGE_NO_CONTACT_INFO: 'contact_us_page_no_contact_info',
};
