import { createSelector } from 'reselect';
import { FETCH_SELECTED_OFFICE_SUCCESS } from './selectOffice';

// Actions
export const SET_SELECTED_OFFICE = 'booking-progress/SET_SELECTED_OFFICE';
const SET_SELECTED_BROKER = 'booking-progress/SET_SELECTED_BROKER';
const SET_SELECTED_TIME_SLOT = 'booking-progress/SET_SELECTED_TIME_SLOT';
const SET_CONTACT_INFO = 'booking-progress/SET_CONTACT_INFO';
const UNSET_SELECTED_TIME_SLOT = 'booking-progress/UNSET_SELECTED_TIME_SLOT';
export const RESET = 'booking-progress/RESET';

export const initialState = {
  office: null,
  broker: null,
  timeSlot: null,
  contactInfo: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_SELECTED_OFFICE_SUCCESS:
    case SET_SELECTED_OFFICE:
      return { ...state, office: action.payload };

    case SET_SELECTED_BROKER:
      return { ...state, broker: action.payload };

    case SET_SELECTED_TIME_SLOT:
      return { ...state, timeSlot: action.payload };

    case SET_CONTACT_INFO:
      return { ...state, contactInfo: action.payload };

    case UNSET_SELECTED_TIME_SLOT:
      return { ...state, timeSlot: null };

    case RESET:
      return initialState;

    default:
      return state;
  }
}

// Selectors
export const bookingProgressSelector = state => state.bookingProgress;
export const selectedOfficeSelector = createSelector(
  bookingProgressSelector,
  progress => progress.office
);
export const selectedBrokerSelector = createSelector(
  bookingProgressSelector,
  progress => progress.broker
);
export const selectedTimeSlotSelector = createSelector(
  bookingProgressSelector,
  progress => progress.timeSlot
);

// Action Creators
export function setSelectedOffice(office) {
  return { type: SET_SELECTED_OFFICE, payload: office };
}

export function setSelectedBroker(broker) {
  return { type: SET_SELECTED_BROKER, payload: broker };
}

export function setSelectedTimeSlot(timeSlot) {
  return { type: SET_SELECTED_TIME_SLOT, payload: timeSlot };
}

export function setContactInfo(contactInfo) {
  return { type: SET_CONTACT_INFO, payload: contactInfo };
}

export function unsetSelectedTimeSlot() {
  return { type: UNSET_SELECTED_TIME_SLOT };
}

export function reset() {
  return { type: RESET };
}
