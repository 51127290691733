import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as fromSettings from '../redux/modules/settings';

function OverridableFormattedMessage({ languageKey, translations }) {
  return (
    <>
      {translations[languageKey] ? (
        <span>{translations[languageKey]}</span>
      ) : (
        <FormattedMessage id={languageKey} />
      )}
    </>
  );
}

OverridableFormattedMessage.propTypes = {
  languageKey: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    translations: fromSettings.customTranslationsSelector(state),
  };
}

export default connect(mapStateToProps)(OverridableFormattedMessage);
