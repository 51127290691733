import React from 'react';
import classNames from 'classnames';

function Loader(props) {
  return (
    <div
      className={classNames('loader-wrapper', {
        'stop-loader': !props.show,
      })}
    >
      <div className="loader-wrapper__page-animation">
        <img alt="Home icon" src="/images/house-icon-2-white.png" />
      </div>
    </div>
  );
}
export default Loader;
