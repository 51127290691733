import React from 'react';
import { FormattedMessage } from 'react-intl';
import { languageKeys } from '../languages/languageKeys';
import { connect } from 'react-redux';
import * as fromBookingProgress from '../redux/modules/bookingProgress';
import Link from 'react-router-dom/es/Link';

function ServiceUnavailablePage({ office, reset }) {
  return (
    <article className="service-unavailable">
      <h1>
        <FormattedMessage id={languageKeys.SERVICE_UNAVAILABLE_HEADING_TEXT} />
      </h1>
      <h3>
        {office && (
          <FormattedMessage
            id={languageKeys.SERVICE_UNAVAILABLE_SUBHEADING_TEXT}
            values={office}
          />
        )}
      </h3>
      <Link to="/" onClick={reset}>
        <button className="btn btn--default">
          <FormattedMessage id={languageKeys.BACK_TO_STARTPAGE} />
        </button>
      </Link>
    </article>
  );
}

function mapStateToProps(state) {
  return {
    office: fromBookingProgress.selectedOfficeSelector(state),
  };
}

export default connect(
  mapStateToProps,
  { reset: fromBookingProgress.reset }
)(ServiceUnavailablePage);
