import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const BrokerProfileImage = ({ broker, active, onClick }) => (
  <li onClick={onClick} className="broker-image">
    <div
      className={classNames(
        'broker-image__wrapper broker-image__wrapper--border',
        { active }
      )}
    >
      {broker.photo ? (
        <figure style={{ backgroundImage: `url(${broker.photo})` }} />
      ) : (
        <figure />
      )}
    </div>
    <div className="broker-image__text-wrapper">
      <span>{broker.firstName}</span>
      <span>{broker.lastName}</span>
    </div>
  </li>
);

BrokerProfileImage.propTypes = {
  broker: PropTypes.object.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BrokerProfileImage;
