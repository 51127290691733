import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { languageKeys } from '../languages/languageKeys';

function OfficeDropdown(props) {
  const { offices, onChange } = props;
  return (
    <select onChange={onChange}>
      <FormattedMessage id={languageKeys.SELECT_YOUR_AREA}>
        {message => <option value="">{message}</option>}
      </FormattedMessage>
      {offices.map(({ eaOfficeId, officeName }) => (
        <option key={eaOfficeId} value={eaOfficeId}>
          {officeName}
        </option>
      ))}
    </select>
  );
}

OfficeDropdown.propTypes = {
  offices: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OfficeDropdown;
