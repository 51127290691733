import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { languageCodeSelector } from '../redux/modules/settings';
import { messages } from './languageHelper';

function mapStateToProps(state) {
  const languageCode = languageCodeSelector(state);
  return {
    locale: languageCode,
    messages: messages[languageCode],
    key: languageCode,
  };
}

export default connect(mapStateToProps)(IntlProvider);
