import PropTypes from 'prop-types';
import React from 'react';

const InfoBox = ({ children }) => {
  return <section className="broker-info slideInBottom-1">{children}</section>;
};

InfoBox.propTypes = {
  children: PropTypes.object.isRequired,
};

export default InfoBox;
