import React, { Component } from 'react';
import { ErrorMessage, Field, Form, withFormik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';
import { ajax } from 'rxjs/ajax';
import { finalize, first } from 'rxjs/operators';
import { languageKeys } from '../languages/languageKeys';
import { API } from '../utils/api';

class UserDetailForm extends Component {
  renderErrorMessage = (fieldName, languageKey) => {
    return (
      <ErrorMessage name={fieldName}>
        {message => (
          <span className="error-message">
            <FormattedMessage
              id={message}
              values={{
                type: this.props.intl.formatMessage({ id: languageKey }),
              }}
            />
          </span>
        )}
      </ErrorMessage>
    );
  };

  render() {
    const { intl, isSubmitting, errors, integrityPolicyUrl } = this.props;

    const integrityPolicyLink = integrityPolicyUrl ? (
      <div className="form-group slideInBottom-5">
        <a
          href={integrityPolicyUrl}
          rel="noopener noreferrer"
          target="_blank"
          className="integrity-url"
        >
          {intl.formatMessage({ id: languageKeys.INTEGRITY_POLICY_LINK_TEXT })}
        </a>
      </div>
    ) : (
      ''
    );

    return (
      <Form>
        {errors.INCORRECT_PHONE_NUMBER_FORMAT && (
          <div className="alert alert--danger">
            <FormattedMessage id={errors.INCORRECT_PHONE_NUMBER_FORMAT} />
          </div>
        )}

        <div className="form-group slideInBottom-1">
          <label>{intl.formatMessage({ id: languageKeys.NAME })}</label>
          {this.renderErrorMessage('name', languageKeys.NAME)}
          <Field
            autoFocus={window.screen.width > 480}
            className="input-form"
            type="text"
            name="name"
          />
        </div>

        <div className="form-group slideInBottom-2">
          <label>{intl.formatMessage({ id: languageKeys.EMAIL })}</label>
          {this.renderErrorMessage('email', languageKeys.EMAIL)}
          <Field type="text" name="email" />
        </div>

        <div className="form-group slideInBottom-3">
          <label>
            {intl.formatMessage({ id: languageKeys.MOBILE_NUMBER })}
          </label>
          {this.renderErrorMessage('phone', languageKeys.MOBILE_NUMBER)}
          <Field type="text" name="phone" />
        </div>

        <div className="form-group slideInBottom-4">
          <label>{intl.formatMessage({ id: languageKeys.ADDRESS })}</label>
          {this.renderErrorMessage('address', languageKeys.ADDRESS)}
          <Field type="text" name="address" />
        </div>

        {integrityPolicyLink}

        <footer>
          <button
            type="button"
            disabled={isSubmitting}
            onClick={this.props.onBack}
            className="btn btn--text"
          >
            <FormattedMessage id={languageKeys.BACK} />
          </button>

          <button
            type="submit"
            className="btn btn--primary"
            disabled={isSubmitting}
          >
            <FormattedMessage id={languageKeys.BOOK} />
          </button>
        </footer>
      </Form>
    );
  }
}

export default withFormik({
  mapPropsToValues() {
    return {
      name: '',
      email: '',
      phone: '',
      address: '',
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required(languageKeys.IS_A_REQUIRED_FIELD),
    email: Yup.string()
      .email(languageKeys.VALIDATION_EMAIL_INVALID)
      .required(languageKeys.IS_A_REQUIRED_FIELD),
    phone: Yup.string().required(languageKeys.IS_A_REQUIRED_FIELD),
    address: Yup.string().required(languageKeys.IS_A_REQUIRED_FIELD),
  }),
  handleSubmit(
    values,
    {
      props: { office, timeSlot, onSuccess, setLoading },
      setSubmitting,
      setErrors,
    }
  ) {
    const body = {
      email: values.email,
      fullName: values.name,
      phoneNumber: values.phone,
      streetAddress: values.address,
      eaOfficeId: office.eaOfficeId,
      eaEmployeeId: timeSlot.broker.eaEmployeeId,
      timeSlotId: timeSlot.event.id,
    };

    setLoading(true);

    ajax
      .post(API.book, body, { 'Content-Type': 'application/json' })
      .pipe(
        first(),
        finalize(() => {
          setSubmitting(false);
          setLoading(false);
        })
      )
      .subscribe(
        () => {
          onSuccess(values);
        },
        error => {
          setErrors({
            [error.response.message]: languageKeys.VALIDATION_PHONE_INVALID,
          });
        }
      );
  },
})(injectIntl(UserDetailForm));
