import React from 'react';
import { Provider } from 'react-redux';
import ConnectedIntlProvider from './languages/ConnectedIntlProvider';
import configureStore from './redux/configureStore';

export default ({ children, initialState = {} }) => {
  return (
    <Provider store={configureStore(initialState)}>
      <ConnectedIntlProvider>{children}</ConnectedIntlProvider>
    </Provider>
  );
};
