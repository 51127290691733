import React from 'react';

function Spinner() {
  const arr = Array(12).fill(null);

  return (
    <div className="spinner">
      {arr.map((value, index) => (
        <div key={index} className={`circle${index + 1} circle`} />
      ))}
    </div>
  );
}

export default Spinner;
