import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import queryString from 'query-string';
import { API } from '../../utils/api';
import { of } from 'rxjs';
import { createSelector } from 'reselect';

// Actions
const FETCH_TIME_SLOTS_FOR_BROKER_REQUEST =
  'select-broker/FETCH_TIME_SLOTS_FOR_BROKER_REQUEST';
const FETCH_TIME_SLOTS_FOR_BROKER_SUCCESS =
  'select-broker/FETCH_TIME_SLOTS_FOR_BROKER_SUCCESS';
const FETCH_TIME_SLOTS_FOR_BROKER_FAILURE =
  'select-broker/FETCH_TIME_SLOTS_FOR_BROKER_FAILURE';

const FETCH_TIME_SLOTS_FOR_OFFICE_REQUEST =
  'select-broker/FETCH_TIME_SLOTS_FOR_OFFICE_REQUEST';
const FETCH_TIME_SLOTS_FOR_OFFICE_SUCCESS =
  'select-broker/FETCH_TIME_SLOTS_FOR_OFFICE_SUCCESS';
const FETCH_TIME_SLOTS_FOR_OFFICE_FAILURE =
  'select-broker/FETCH_TIME_SLOTS_FOR_OFFICE_FAILURE';

// Reducer
export const initialState = { loading: true, timeSlots: {} };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_TIME_SLOTS_FOR_BROKER_REQUEST:
    case FETCH_TIME_SLOTS_FOR_OFFICE_REQUEST:
      return { ...state, loading: true };

    case FETCH_TIME_SLOTS_FOR_BROKER_SUCCESS:
    case FETCH_TIME_SLOTS_FOR_OFFICE_SUCCESS:
      return { ...state, loading: false, timeSlots: action.payload };

    case FETCH_TIME_SLOTS_FOR_BROKER_FAILURE:
    case FETCH_TIME_SLOTS_FOR_OFFICE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}

// Selectors
const getState = state => state.selectTimeSlot;
export const loadingSelector = createSelector(getState, state => state.loading);
export const timeSlotsSelector = createSelector(
  getState,
  state => state.timeSlots
);

// Action Creators
export const fetchTimeSlotsForBrokerRequest = ({
  eaEmployeeId,
  start,
  end,
}) => ({
  type: FETCH_TIME_SLOTS_FOR_BROKER_REQUEST,
  payload: {
    eaEmployeeId,
    params: { start, end },
  },
});

export const fetchTimeSlotsForBrokerSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_FOR_BROKER_SUCCESS,
  payload: timeSlots,
});

export const fetchTimeSlotsForBrokerFailure = error => ({
  type: FETCH_TIME_SLOTS_FOR_BROKER_FAILURE,
  payload: error,
});

export const fetchTimeSlotsForOfficeRequest = ({ eaOfficeId, start, end }) => ({
  type: FETCH_TIME_SLOTS_FOR_OFFICE_REQUEST,
  payload: {
    eaOfficeId,
    params: { start, end },
  },
});

export const fetchTimeSlotsForOfficeSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_FOR_OFFICE_SUCCESS,
  payload: timeSlots,
});

export const fetchTimeSlotsForOfficeFailure = error => ({
  type: FETCH_TIME_SLOTS_FOR_OFFICE_FAILURE,
  payload: error,
});

export const fetchTimeSlotsForBrokerEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_TIME_SLOTS_FOR_BROKER_REQUEST),
    switchMap(({ payload: { eaEmployeeId, params } }) => {
      const queryParams = queryString.stringify(params);
      return ajax
        .getJSON(`${API.timeSlotsForBroker(eaEmployeeId)}/?${queryParams}`)
        .pipe(
          map(response => fetchTimeSlotsForBrokerSuccess(response)),
          catchError(err => of(fetchTimeSlotsForBrokerFailure(err)))
        );
    })
  );

export const fetchTimeSlotsForOfficeEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_TIME_SLOTS_FOR_OFFICE_REQUEST),
    switchMap(({ payload: { eaOfficeId, params } }) => {
      const queryParams = queryString.stringify(params);
      return ajax
        .getJSON(`${API.timeSlotsForOffice(eaOfficeId)}/?${queryParams}`)
        .pipe(
          map(response => fetchTimeSlotsForOfficeSuccess(response)),
          catchError(err => of(fetchTimeSlotsForOfficeFailure(err)))
        );
    })
  );

export const epics = [fetchTimeSlotsForBrokerEpic, fetchTimeSlotsForOfficeEpic];
