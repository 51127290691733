import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import shuffle from 'shuffle-array';
import bookingProgress from './modules/bookingProgress';
import loading from './modules/loading';
import selectBroker, {
  epics as selectBrokerEpics,
} from './modules/selectBroker';
import selectOffice, { epics as officeEpics } from './modules/selectOffice';
import settings, { epics as settingEpics } from './modules/settings';
import selectTimeSlot, {
  epics as selectTimeSlotEpics,
} from './modules/selectTimeSlot';

const rootReducer = combineReducers({
  loading,
  selectTimeSlot,
  selectBroker,
  selectOffice,
  bookingProgress,
  settings,
});

const rootEpic = combineEpics(
  ...settingEpics,
  ...officeEpics,
  ...selectBrokerEpics,
  ...selectTimeSlotEpics
);
const composeEnhancers = composeWithDevTools({ name: 'Book Broker' });

const configureStore = initialState => {
  // https://github.com/redux-observable/redux-observable/issues/389
  const epicMiddleware = createEpicMiddleware({
    dependencies: { ajax, shuffle },
  });
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(rootEpic);

  return store;
};

export default configureStore;
