import { ErrorMessage, Field, Form, withFormik } from 'formik';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ajax } from 'rxjs/ajax';
import { finalize, first } from 'rxjs/operators';
import * as Yup from 'yup';
import { languageKeys } from '../languages/languageKeys';
import { API } from '../utils/api';
import OverridableFormattedMessage from '../languages/OverridableFormattedMessage';

function ZipForm({ intl, isSubmitting, errors }) {
  return (
    <Form className="zip-form form-wrapper">
      {errors.NO_OFFICE_FOUND && (
        <div className="alert alert--danger">
          <FormattedMessage id={errors.NO_OFFICE_FOUND} />
        </div>
      )}

      <div className="form-group">
        <label>{intl.formatMessage({ id: languageKeys.ZIP })}</label>
        <ErrorMessage name="zip">
          {key => (
            <span className="error-message">
              <FormattedMessage
                id={key}
                values={{
                  type: intl.formatMessage({ id: languageKeys.ZIP }),
                }}
              />
            </span>
          )}
        </ErrorMessage>
        <Field type="text" name="zip" />
        <button
          className="btn btn--primary"
          type="submit"
          disabled={isSubmitting}
        >
          <OverridableFormattedMessage
            languageKey={languageKeys.OFFICE_PAGE_FIND_OFFICE}
          />
        </button>
      </div>
    </Form>
  );
}

export default withFormik({
  mapPropsToValues() {
    return {
      zip: '',
    };
  },
  validationSchema: Yup.object().shape({
    zip: Yup.string().required(languageKeys.IS_A_REQUIRED_FIELD),
  }),
  handleSubmit({ zip }, { props, setSubmitting, setErrors }) {
    props.setLoading(true);

    ajax
      .getJSON(API.officeByZip(zip))
      .pipe(
        first(),
        finalize(() => {
          setSubmitting(false);
          props.setLoading(false);
        })
      )
      .subscribe(
        office => {
          props.onSubmit(office);
        },
        () => {
          setErrors({
            NO_OFFICE_FOUND: languageKeys.NO_OFFICE_FOUND,
          });
        }
      );
  },
})(injectIntl(ZipForm));
