import React from 'react';
import PropTypes from 'prop-types';

const Chip = props => (
  <button onClick={props.onRemove} className="btn btn--primary btn--chip">
    <span>{props.children}</span>
    <i className="close" />
  </button>
);

Chip.propTypes = {
  children: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Chip;
