import React, { Component } from 'react';
import { connect } from 'react-redux';
import { languageKeys } from '../languages/languageKeys';
import { FormattedMessage } from 'react-intl';
import * as fromBookingProgress from '../redux/modules/bookingProgress';
import Chip from '../components/Chip';
import { Redirect } from 'react-router-dom';
import * as fromSelectOffice from '../redux/modules/selectOffice';

class ContactUsPage extends Component {
  state = {
    navigateToStart: false,
  };

  navigateToStart = () => {
    this.props.reset();
    return <Redirect to="/" />;
  };

  render() {
    if (this.state.navigateToStart || !this.props.selectedOffice) {
      return this.navigateToStart();
    }

    const email = this.props.selectedOffice
      ? this.props.selectedOffice.email
      : null;
    const phone = this.props.selectedOffice
      ? this.props.selectedOffice.phone
      : null;

    const contactInfo = (
      <section className="contact-us-page__contact-info slideInBottom-2">
        {phone ? (
          <p>
            <a href={`tel:${phone}`}>
              <i className="icon-call-end" />
              {phone}
            </a>
          </p>
        ) : null}
        {email ? (
          <p>
            <a href={`mailto:${email}`}>
              <i className="icon-envelope" />
              {email}
            </a>
          </p>
        ) : null}
        {!email && !phone ? (
          <p>
            <FormattedMessage
              id={languageKeys.CONTACT_US_PAGE_NO_CONTACT_INFO}
            />
          </p>
        ) : null}
      </section>
    );

    return (
      <article className="contact-us-page">
        <header className="slideInBottom">
          <Chip onRemove={() => this.setState({ navigateToStart: true })}>
            {this.props.selectedOffice.officeName}
          </Chip>
        </header>
        <section className="slideInBottom-1">
          <h1>
            <FormattedMessage id={languageKeys.CONTACT_US_PAGE_HEADING} />
          </h1>
          <p>
            <FormattedMessage id={languageKeys.CONTACT_US_PAGE_TEXT} />
          </p>
        </section>
        {contactInfo}
        <footer className="slideInBottom-3">
          <span
            className="btn btn--text"
            onClick={() => this.setState({ navigateToStart: true })}
          >
            <FormattedMessage id={languageKeys.BACK} />
          </span>
        </footer>
      </article>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedOffice: fromBookingProgress.selectedOfficeSelector(state),
    officeIsLoading: fromSelectOffice.loadingSelector(state),
  };
};

export default connect(
  mapStateToProps,
  {
    reset: fromBookingProgress.reset,
  }
)(ContactUsPage);
