import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { API } from '../../utils/api';
import { RESET } from './bookingProgress';

// Actions
const FETCH_BROKERS_REQUEST = 'select-broker/FETCH_BROKERS_REQUEST';
const FETCH_BROKERS_SUCCESS = 'select-broker/FETCH_BROKERS_SUCCESS';
const FETCH_BROKERS_FAILURE = 'select-broker/FETCH_BROKERS_FAILURE';

// Reducer
export const initialState = { loading: true, brokers: [] };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_BROKERS_REQUEST:
      return { ...state, loading: true };

    case FETCH_BROKERS_SUCCESS:
      return { ...state, loading: false, brokers: action.payload };

    case FETCH_BROKERS_FAILURE:
      return { ...state, loading: false };

    case RESET:
      return initialState;

    default:
      return state;
  }
}

// Selectors
const getState = state => state.selectBroker;
export const loadingSelector = createSelector(getState, state => state.loading);
export const brokersSelector = createSelector(getState, state => state.brokers);

// Action Creators
export const fetchBrokersRequest = eaOfficeId => ({
  type: FETCH_BROKERS_REQUEST,
  payload: eaOfficeId,
});

export const fetchBrokersSuccess = brokers => ({
  type: FETCH_BROKERS_SUCCESS,
  payload: brokers,
});

export const fetchBrokersFailure = error => ({
  type: FETCH_BROKERS_FAILURE,
  payload: error,
});

// Side Effects
export const fetchBrokersEpic = (action$, state$, { ajax, shuffle }) =>
  action$.pipe(
    ofType(FETCH_BROKERS_REQUEST),
    switchMap(({ payload }) =>
      ajax.getJSON(API.brokers(payload)).pipe(
        map(brokers => shuffle(brokers, { copy: true })),
        map(brokers => fetchBrokersSuccess(brokers)),
        catchError(err => of(fetchBrokersFailure(err)))
      )
    )
  );

export const epics = [fetchBrokersEpic];
