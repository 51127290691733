import React from 'react';

function Preloader() {
  return (
    <>
      <div className="preloader-2 stop-preloader-2" />
      <div className="preloader stop-preloader">
        <img alt="Home icon" src="/images/house-icon-2.png" />
      </div>
    </>
  );
}

export default Preloader;
