import PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader';

function MainContentBox(props) {
  return (
    <>
      <Link to="/" className="customer-logo">
        <div alt="Logotype" className="customer-logo__img" />
        <img alt="Logotype" className="customer-logo__img" />
      </Link>
      <div className="MainContentBox">
        <Loader show={props.loading} />
        {props.children}
      </div>
    </>
  );
}

MainContentBox.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainContentBox;
