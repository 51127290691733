import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo } from 'rxjs/operators';
import { API } from '../../utils/api';
import { createSelector } from 'reselect';
import { SET_SELECTED_OFFICE } from './bookingProgress';

// Actions
const FETCH_OFFICES_REQUEST = 'select-office/FETCH_OFFICES_REQUEST';
const FETCH_OFFICES_SUCCESS = 'select-office/FETCH_OFFICES_SUCCESS';
const FETCH_OFFICES_FAILURE = 'select-office/FETCH_OFFICES_FAILURE';

const FETCH_SELECTED_OFFICE_REQUEST =
  'select-office/FETCH_SELECTED_OFFICE_REQUEST';
export const FETCH_SELECTED_OFFICE_SUCCESS =
  'select-office/FETCH_SELECTED_OFFICE_SUCCESS';
const FETCH_SELECTED_OFFICE_FAILURE =
  'select-office/FETCH_SELECTED_OFFICE_FAILURE';

// Reducer
export const initialState = { loading: true, offices: [] };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_OFFICES_REQUEST:
    case FETCH_SELECTED_OFFICE_REQUEST:
      return { ...state, loading: true };

    case FETCH_OFFICES_SUCCESS:
      return { ...state, loading: false, offices: action.payload };

    case FETCH_SELECTED_OFFICE_SUCCESS:
      return { ...state, loading: false };

    case FETCH_OFFICES_FAILURE:
    case FETCH_SELECTED_OFFICE_FAILURE:
      return { ...state, loading: false, offices: [] };

    case SET_SELECTED_OFFICE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

// Selectors
const getState = state => state.selectOffice;
export const officesSelector = createSelector(getState, state => state.offices);
export const loadingSelector = createSelector(getState, state => state.loading);

// Action Creators
export function fetchOfficesRequest() {
  return { type: FETCH_OFFICES_REQUEST };
}

export function fetchOfficesSuccess(offices) {
  return { type: FETCH_OFFICES_SUCCESS, payload: offices };
}

export function fetchOfficesFailure(error) {
  return { type: FETCH_OFFICES_FAILURE, payload: error };
}

export function fetchSelectedOfficeRequest(eaOfficeId) {
  return { type: FETCH_SELECTED_OFFICE_REQUEST, payload: eaOfficeId };
}

export function fetchSelectedOfficeSuccess(office) {
  return { type: FETCH_SELECTED_OFFICE_SUCCESS, payload: office };
}

export function fetchSelectedOfficeFailure(error) {
  return { type: FETCH_SELECTED_OFFICE_FAILURE, payload: error };
}

// Side Effects
export const fetchOfficesEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_OFFICES_REQUEST),
    switchMapTo(
      ajax.getJSON(API.offices).pipe(
        map(response => fetchOfficesSuccess(response)),
        catchError(err => of(fetchOfficesFailure(err)))
      )
    )
  );

export const fetchSelectedOfficeEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_SELECTED_OFFICE_REQUEST),
    switchMap(({ payload }) =>
      ajax.getJSON(API.office(payload)).pipe(
        map(response => fetchSelectedOfficeSuccess(response)),
        catchError(err => of(fetchSelectedOfficeFailure(err)))
      )
    )
  );

export const epics = [fetchOfficesEpic, fetchSelectedOfficeEpic];
