import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import UserDetailForm from '../components/UserDetailForm';
import Chip from '../components/Chip';
import { languageKeys } from '../languages/languageKeys';
import * as fromBookingProgress from '../redux/modules/bookingProgress';
import * as fromSettings from '../redux/modules/settings';
import * as fromLoading from '../redux/modules/loading';
import OverridableFormattedMessage from '../languages/OverridableFormattedMessage';

class UserDetailPage extends Component {
  state = {
    redirectToHome: false,
    redirectToConfirmationPage: false,
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  handleFormSubmit = contactInfo => {
    this.props.setContactInfo(contactInfo);
    this.setState({ redirectToConfirmationPage: true });
  };

  redirectToHome = () => {
    this.props.reset();
    this.setState({ redirectToHome: true });
  };

  render() {
    const { office, timeSlot, integrityPolicyUrl } = this.props;
    const { redirectToHome, redirectToConfirmationPage } = this.state;

    if (!office || !timeSlot || redirectToHome) {
      return <Redirect to="/" />;
    }

    if (redirectToConfirmationPage) {
      return <Redirect push to="/confirmation" />;
    }

    return (
      <article className="user-detail-page">
        <header>
          <Chip onRemove={this.redirectToHome}>{office.officeName}</Chip>
          <Chip onRemove={this.props.history.goBack}>
            {`${timeSlot.broker.fullName}, ${moment(
              timeSlot.event.startTime
            ).format('DD MMM, HH:mm')}`}
          </Chip>
        </header>
        <h1>
          <OverridableFormattedMessage
            languageKey={languageKeys.USER_DETAIL_PAGE_HEADING_TEXT}
          />
        </h1>
        <section>
          <UserDetailForm
            office={office}
            timeSlot={timeSlot}
            integrityPolicyUrl={integrityPolicyUrl}
            onBack={this.props.history.goBack}
            onSuccess={this.handleFormSubmit}
            setLoading={this.props.setLoading}
          />
        </section>
      </article>
    );
  }
}

function mapStateToProps(state) {
  return {
    office: fromBookingProgress.selectedOfficeSelector(state),
    timeSlot: fromBookingProgress.selectedTimeSlotSelector(state),
    integrityPolicyUrl: fromSettings.integrityPolicyUrlSelector(state),
  };
}

export default connect(
  mapStateToProps,
  {
    reset: fromBookingProgress.reset,
    setContactInfo: fromBookingProgress.setContactInfo,
    setLoading: fromLoading.setLoading,
  }
)(UserDetailPage);
