import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import BrokerProfileImage from './BrokerProfileImage';
import InfoBox from './InfoBox';
import BrokerInfo from './BrokerInfo';
import OfficeInfo from './OfficeInfo';
import { languageKeys } from '../languages/languageKeys';
import { isEmpty } from 'ramda';

class BrokerSelection extends Component {
  render() {
    const { brokers, selectedBroker, selectedOffice } = this.props;

    return (
      <>
        <div className="broker-selection">
          <ul className="slideInBottom">
            <li
              className="broker-image"
              onClick={() => this.props.onBrokerChange(null)}
            >
              <div
                className={classNames(
                  'broker-image__wrapper broker-image__wrapper--border',
                  { active: isEmpty(selectedBroker) }
                )}
              >
                <div className="office-figure" />
              </div>
              <div className="broker-image__text-wrapper">
                <FormattedMessage id={languageKeys.ALL} />
                <FormattedMessage id={languageKeys.BROKERS} />
              </div>
            </li>

            {brokers.map(broker => (
              <BrokerProfileImage
                key={broker.eaEmployeeId}
                broker={broker}
                active={selectedBroker.eaEmployeeId === broker.eaEmployeeId}
                onClick={() => this.props.onBrokerChange(broker)}
              />
            ))}
          </ul>
        </div>
        <InfoBox>
          {isEmpty(selectedBroker) ? (
            <OfficeInfo
              office={selectedOffice}
              message={languageKeys.CONTACT_US}
            />
          ) : (
            <BrokerInfo
              broker={selectedBroker}
              office={selectedOffice}
              message={languageKeys.CONTACT_ME}
            />
          )}
        </InfoBox>
      </>
    );
  }
}

BrokerSelection.propTypes = {
  brokers: PropTypes.array,
  selectedBroker: PropTypes.object.isRequired,
  selectedOffice: PropTypes.object.isRequired,
  onBrokerChange: PropTypes.func.isRequired,
};

export default BrokerSelection;
