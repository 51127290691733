import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import MainContentBox from '../components/MainContentBox';
import NotFoundPage from '../components/NotFoundPage';
import ServiceUnavailablePage from '../components/ServiceUnavailablePage';
import ContactUsPage from './ContactUsPage';
import Preloader from '../components/Preloader';
import * as fromLoading from '../redux/modules/loading';
import * as fromSettings from '../redux/modules/settings';
import BrokerPage from './BrokerPage';
import ConfirmationPage from './ConfirmationPage';
import OfficePage from './OfficePage';
import UserDetailPage from './UserDetailPage';
import Spinner from '../components/Spinner';

class App extends Component {
  componentDidMount() {
    this.props.fetchSettings();
  }

  renderMainContent = () => {
    return (
      <MainContentBox loading={this.props.loading}>
        <Switch>
          <Route path="/" component={OfficePage} exact />
          <Route
            path="/offices/:eaOfficeId/:eaEmployeeId"
            component={BrokerPage}
          />
          <Route path="/offices/:eaOfficeId" component={BrokerPage} />
          <Route path="/user-details" component={UserDetailPage} />
          <Route path="/confirmation" component={ConfirmationPage} />
          <Route
            path="/service-unavailable"
            component={ServiceUnavailablePage}
          />
          <Route path="/contact-us" component={ContactUsPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </MainContentBox>
    );
  };

  render() {
    return (
      <div className="App">
        <Preloader />
        {this.props.settingsLoading ? <Spinner /> : this.renderMainContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: fromLoading.loadingSelector(state),
    settingsLoading: fromSettings.loadingSelector(state),
    customer: fromSettings.customerSelector(state),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { fetchSettings: fromSettings.fetchSettingsRequest }
  )
)(App);
