import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import BrokerSelection from '../components/BrokerSelection';
import Calendar from '../components/Calendar';
import Chip from '../components/Chip';
import Spinner from '../components/Spinner';
import { languageKeys } from '../languages/languageKeys';
import * as fromBookingProgress from '../redux/modules/bookingProgress';
import * as fromSelectOffice from '../redux/modules/selectOffice';
import * as fromSelectBroker from '../redux/modules/selectBroker';
import * as fromSelectTimeSlot from '../redux/modules/selectTimeSlot';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const TIMESLOT = {
  OFFICE: 'OFFICE',
  BROKER: 'BROKER',
};

class BrokerPage extends Component {
  state = {
    now: moment().utc(),
    navigateBack: false,
  };

  componentDidMount() {
    const {
      fetchBrokers,
      fetchSelectedOffice,
      unsetSelectedTimeSlot,
      match: { params },
    } = this.props;

    this.fetchTimeSlots(TIMESLOT.OFFICE);
    fetchBrokers(params.eaOfficeId);
    fetchSelectedOffice(params.eaOfficeId);
    unsetSelectedTimeSlot();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { now } = this.state;
    const {
      brokers,
      match: { params },
      setSelectedBroker,
    } = this.props;
    const isSameDateTime = prevState.now.isSame(now);

    if (brokers.length > 0) {
      const selectedBroker = brokers.find(
        broker => broker.eaEmployeeId === params.eaEmployeeId
      );
      if (selectedBroker) {
        setSelectedBroker(selectedBroker);
      } else {
        setSelectedBroker(null);
      }
    }

    if (
      !isSameDateTime ||
      this.props.selectedBroker !== prevProps.selectedBroker
    ) {
      this.fetchTimeSlots(
        this.props.selectedBroker ? TIMESLOT.BROKER : TIMESLOT.OFFICE
      );
    }
  }

  navigateBack = () => {
    this.props.reset();
    this.setState({ navigateBack: true });
  };

  handleNextButtonClick = event => {
    if (!this.props.selectedTimeSlot) {
      event.preventDefault();
    }
  };

  fetchTimeSlots = (type = TIMESLOT.OFFICE) => {
    const {
      fetchTimeSlotsForBroker,
      fetchTimeSlotsForOffice,
      selectedBroker,
      match: { params },
    } = this.props;

    if (type === TIMESLOT.BROKER) {
      return fetchTimeSlotsForBroker({
        eaEmployeeId: selectedBroker.eaEmployeeId,
        ...this.getTimePeriod(),
      });
    }

    fetchTimeSlotsForOffice({
      eaOfficeId: params.eaOfficeId,
      ...this.getTimePeriod(),
    });
  };

  getTimePeriod = () => {
    const startOfWeek = this.state.now.clone().startOf('week');
    const now = moment().utc();

    return {
      start: startOfWeek.isBefore(now) ? now.format() : startOfWeek.format(),
      end: this.state.now
        .clone()
        .endOf('week')
        .format(),
    };
  };

  handleSelectBroker = broker => {
    const {
      history,
      match: { params },
    } = this.props;
    const eaEmployeeId =
      broker && broker.eaEmployeeId ? broker.eaEmployeeId : null;
    const url = eaEmployeeId
      ? `/offices/${params.eaOfficeId}/${eaEmployeeId}`
      : `/offices/${params.eaOfficeId}`;
    history.push(url);
  };

  render() {
    const {
      brokerIsLoading,
      officeIsLoading,
      timeSlotsIsLoading,
      selectedBroker,
      selectedOffice,
      selectedTimeSlot,
      brokers,
      timeSlots,
      setSelectedTimeSlot,
    } = this.props;

    if (this.state.navigateBack) {
      return <Redirect to="/" />;
    } else if (brokerIsLoading || officeIsLoading) {
      return <Spinner />;
    } else if (brokers.length === 0) {
      return <Redirect to="/contact-us" />;
    } else if (!selectedOffice) {
      return null;
    }

    return (
      <article className="broker-page">
        <header>
          <Chip onRemove={this.navigateBack}>{selectedOffice.officeName}</Chip>
        </header>
        <section>
          <BrokerSelection
            brokers={brokers}
            selectedBroker={selectedBroker || {}}
            onBrokerChange={broker => this.handleSelectBroker(broker)}
            selectedOffice={selectedOffice}
          />
        </section>
        <section>
          <Calendar
            timeSlots={timeSlots}
            now={this.state.now}
            onWeekChange={now => this.setState({ now })}
            selectedTime={selectedTimeSlot}
            onTimeChange={timeSlot => setSelectedTimeSlot(timeSlot)}
            loading={timeSlotsIsLoading}
          />
        </section>
        <footer>
          <span className="btn btn--text" onClick={this.navigateBack}>
            <FormattedMessage id={languageKeys.BACK} />
          </span>

          <Link
            to="/user-details"
            role="button"
            onClick={this.handleNextButtonClick}
            data-tip
            data-for="choose-date-message"
            data-tip-disable={selectedTimeSlot}
          >
            <button className="btn btn--primary" disabled={!selectedTimeSlot}>
              <FormattedMessage id={languageKeys.NEXT} />
            </button>
          </Link>
          <ReactTooltip id="choose-date-message">
            <FormattedMessage id={languageKeys.CHOOSE_DATE} />
          </ReactTooltip>
        </footer>
      </article>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedOffice: fromBookingProgress.selectedOfficeSelector(state),
    selectedBroker: fromBookingProgress.selectedBrokerSelector(state),
    selectedTimeSlot: fromBookingProgress.selectedTimeSlotSelector(state),
    brokers: fromSelectBroker.brokersSelector(state),
    timeSlots: fromSelectTimeSlot.timeSlotsSelector(state),
    brokerIsLoading: fromSelectBroker.loadingSelector(state),
    officeIsLoading: fromSelectOffice.loadingSelector(state),
    timeSlotsIsLoading: fromSelectTimeSlot.loadingSelector(state),
  };
}

export default connect(
  mapStateToProps,
  {
    fetchSelectedOffice: fromSelectOffice.fetchSelectedOfficeRequest,
    fetchBrokers: fromSelectBroker.fetchBrokersRequest,
    fetchTimeSlotsForBroker: fromSelectTimeSlot.fetchTimeSlotsForBrokerRequest,
    fetchTimeSlotsForOffice: fromSelectTimeSlot.fetchTimeSlotsForOfficeRequest,
    setSelectedBroker: fromBookingProgress.setSelectedBroker,
    setSelectedTimeSlot: fromBookingProgress.setSelectedTimeSlot,
    unsetSelectedTimeSlot: fromBookingProgress.unsetSelectedTimeSlot,
    reset: fromBookingProgress.reset,
  }
)(BrokerPage);
